import React from "react";
import { QueryClient, dehydrate } from "react-query";
import Queries from "@/constants/queries";
import PublicService from "@/services/api/public.service";
import dynamic from "next/dynamic";
import { apiUrl } from "@/config/config";
import { apiEndpoint } from "@/services/apiEndpoint";
// client components
const CustomHead = dynamic(() => import("@/components/common/head/Head"));
const Hero = dynamic(() => import("@/components/pages/homepage/Hero"));
const AboutSection = dynamic(() =>
  import("@/components/pages/homepage/AboutSection")
);
const FeaturesSection = dynamic(() =>
  import("@/components/pages/homepage/FeaturesSection")
);
const ChooseUsSection = dynamic(() =>
  import("@/components/pages/homepage/ChooseUsSection")
);
const BlogSection = dynamic(() =>
  import("@/components/pages/homepage/BlogSection")
);
const TestimonialsSection = dynamic(() =>
  import("@/components/pages/homepage/TestimonialsSection")
);
const Newsletter = dynamic(() =>
  import("@/components/pages/homepage/Newsletter")
);



// export async function getStaticProps() {
// 	const queryClient = new QueryClient();

// 	await queryClient.prefetchQuery({
// 		queryKey: [Queries.cmsHomePage],
// 		queryFn: PublicService.getHomePageData,
// 	});

// 	return {
// 		props: {
// 			dehydratedState: dehydrate(queryClient),
// 		},
// 	};
// }

const HomePage = ({
  aboutTopItemData,
  aboutItemData,
  featureData,
  TestimonialData,
  chooseUsData,
  HomePageFeatureData,
}) => {
  return (
    <>
      <CustomHead />
      <div className="home-content-area">
        <Hero />
        <AboutSection
          topItemData={aboutTopItemData}
          aboutItemData={aboutItemData}
        />
        <FeaturesSection featureData={HomePageFeatureData} />
        <ChooseUsSection chooseUsItem={chooseUsData} />
        <BlogSection />
        <TestimonialsSection TestimonialData={TestimonialData} />
        {/* <SponsorSection /> */}
        <Newsletter />
      </div>
    </>
  );
};

export default HomePage;

export async function getServerSideProps() {
  // Fetch data from external API

  const queryClient = new QueryClient();

  await queryClient.prefetchQuery({
    queryKey: [Queries.cmsHomePage],
    queryFn: PublicService.getHomePageData,
  });

  const getAboutPageTopItem = await fetch(
    apiUrl + apiEndpoint?.landingPage?.aboutTopPageData
  );
  const aboutTopItemData = await getAboutPageTopItem.json();

  const getAboutItem = await fetch(apiUrl + apiEndpoint.landingPage.aboutItems);
  const aboutItemData = await getAboutItem.json();

  const getHomePageFeature = await fetch(
    apiUrl + apiEndpoint.csmFeatures.allFeatures
  );
  const featureData = await getHomePageFeature.json();

  const getChooseUSItem = await fetch(
    apiUrl + apiEndpoint.landingPage.chooseUs
  );
  const chooseUsData = await getChooseUSItem.json();

  const getTestimonial = await fetch(
    apiUrl + apiEndpoint.landingPage.testimonial
  );
  const TestimonialData = await getTestimonial.json();

  const HomePageFeatures = await fetch(
    apiUrl + apiEndpoint.landingPage.HomePageFeatures
  );
  const HomePageFeatureData = await HomePageFeatures.json();

  return {
    props: {
      aboutTopItemData,
      aboutItemData,
      featureData,
	  chooseUsData,
	  TestimonialData,
    HomePageFeatureData,
      dehydratedState: dehydrate(queryClient),
    },
  };
}
